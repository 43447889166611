<template>
    <ValidationObserver ref="observer">
        <b-form>
            <EKDialog
                title="إضافة محاضرة"
                ref="courseDialog"
                @open="$store.commit('Reset_Lecture_Dto')"
                @ok="onSubmit"
                @search="search"
                btnText="إضافة محاضرة"
            >
                <template #body>
                    <EKInputText
                        :rules="[
                            { type: 'required', message: 'اسم المحاضرة إجباري' }
                        ]"
                        label="اسم المحاضرة"
                        v-model="lectureDto.name"
                        placeholder="ادخل اسم المحاضرة"
                        name="name"
                    />
                    <EKInputSelect
                        v-model="lectureDto.year"
                        label="سنة المحاضرة"
                        placeholder="ادخل سنة المحاضرة"
                        :rules="[
                            { type: 'required', message: 'سنة المحاضرة إجباري' }
                        ]"
                        :options="years"
                        name="years"
                    />
                    <EKInputSelect
                        label="المادة"
                        placeholder="اختر المادة"
                        :rules="[
                            {
                                type: 'required',
                                message:
                                    'أدخل المادة التي تكون المحاضرة تابعة  لها'
                            }
                        ]"
                        :options="subjectsList"
                        v-model="lectureDto.subjectId"
                        name="subjectId"
                    />
                    <EKInputSelect
                        label="تصنيفات المحاضرة"
                        placeholder="اختر تصنيفات"
                        :rules="[
                            {
                                type: 'required',
                                message:
                                    'اختر التصنيفات التي تكون المحاضرة تابعة  لها'
                            }
                        ]"
                        multiple
                        :options="tagsList"
                        v-model="lectureDto.categories"
                        name="categories"
                    />
                    <EKInputSelect
                        label="دكاترة المحاضرة"
                        placeholder="اختر الدكاترة"
                        :options="doctors"
                        v-model="lectureDto.doctors"
                        name="doctors"
                    />
                    <label>ملف المحاضرة</label>
                    <b-form-file
                        ref="fileLecture"
                        @input="getFile($event)"
                        placeholder="Choose a file or drop it here..."
                    ></b-form-file>
                </template>
            </EKDialog>
        </b-form>
    </ValidationObserver>
</template>
<script>
import EKDialog from "@Ekcore/components/EK-dialog";
import EKInputText from "@Ekcore/components/EK-forms/EK-input-text";
import EKInputSelect from "@Ekcore/components/EK-forms/EK-input-select";
import { mapState, mapActions, mapGetters } from "vuex";
import { ValidationObserver } from "vee-validate";
import { BToast } from "bootstrap-vue";

export default {
    components: {
        EKDialog,
        EKInputText,
        EKInputSelect,
        ValidationObserver
    },
    computed: {
        ...mapGetters(["years", "doctors", "tagsList"]),
        ...mapState({
            lectureDto: state => state.lecture.lectureDto,
            subjectsList: state => state.subjects.subjectsList
        })
    },
    created() {
        this.fetchTotalTag();
        this.fetchSubject({ semesterId: "", year: "", facultyId: "" });
    },
    methods: {
        ...mapActions(["fetchSubject", "fetchTotalTag", "addLecture"]),
        onSubmit() {
            this.$refs.observer.validate().then(success => {
                if (success && Boolean(this.lectureDto.file)) {
                    this.addLecture(this.lectureDto);
                    this.$refs.courseDialog.close();
                    this.$refs.fileLecture.reset();
                    this.$store.commit("Reset_Lecture_Dto");
                } else {
                    const bToast = new BToast();
                    bToast.$bvToast.toast("ادخل ملف للمحاضرة..", {
                        title: " الملف مطلوب  ",
                        variant: "danger",
                        toaster: "b-toaster-top-right",
                        solid: true,
                        autoHideDelay: 2000,
                        appendToast: true
                    });
                }
            });
        },
        getFile(file) {
            console.log("file", file);
            const ext = file.name
                .split("?")[0]
                .split("#")[0]
                .split(".")
                .pop();
            console.log("name file", ext);
            if (ext == "pdf") {
                this.lectureDto.file = file;
            } else {
                const bToast = new BToast();
                bToast.$bvToast.toast("الملف غير مدعوم .. حصرا PDF ", {
                    title: "الملف غير مدعوم  ",
                    variant: "danger",
                    toaster: "b-toaster-top-right",
                    solid: true,
                    autoHideDelay: 2000,
                    appendToast: true
                });
            }
        },
        search(query) {
            this.$store.commit("Set_Search_Dto", {
                keys: ["name"],
                query
            });
        }
    },
    beforeDestroy() {
        this.$store.commit("Reset_Lecture_Dto");
    }
};
</script>
