var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observer"},[_c('b-form',[_c('EKDialog',{ref:"courseDialog",attrs:{"title":"إضافة محاضرة","btnText":"إضافة محاضرة"},on:{"open":function($event){return _vm.$store.commit('Reset_Lecture_Dto')},"ok":_vm.onSubmit,"search":_vm.search},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('EKInputText',{attrs:{"rules":[
                        { type: 'required', message: 'اسم المحاضرة إجباري' }
                    ],"label":"اسم المحاضرة","placeholder":"ادخل اسم المحاضرة","name":"name"},model:{value:(_vm.lectureDto.name),callback:function ($$v) {_vm.$set(_vm.lectureDto, "name", $$v)},expression:"lectureDto.name"}}),_c('EKInputSelect',{attrs:{"label":"سنة المحاضرة","placeholder":"ادخل سنة المحاضرة","rules":[
                        { type: 'required', message: 'سنة المحاضرة إجباري' }
                    ],"options":_vm.years,"name":"years"},model:{value:(_vm.lectureDto.year),callback:function ($$v) {_vm.$set(_vm.lectureDto, "year", $$v)},expression:"lectureDto.year"}}),_c('EKInputSelect',{attrs:{"label":"المادة","placeholder":"اختر المادة","rules":[
                        {
                            type: 'required',
                            message:
                                'أدخل المادة التي تكون المحاضرة تابعة  لها'
                        }
                    ],"options":_vm.subjectsList,"name":"subjectId"},model:{value:(_vm.lectureDto.subjectId),callback:function ($$v) {_vm.$set(_vm.lectureDto, "subjectId", $$v)},expression:"lectureDto.subjectId"}}),_c('EKInputSelect',{attrs:{"label":"تصنيفات المحاضرة","placeholder":"اختر تصنيفات","rules":[
                        {
                            type: 'required',
                            message:
                                'اختر التصنيفات التي تكون المحاضرة تابعة  لها'
                        }
                    ],"multiple":"","options":_vm.tagsList,"name":"categories"},model:{value:(_vm.lectureDto.categories),callback:function ($$v) {_vm.$set(_vm.lectureDto, "categories", $$v)},expression:"lectureDto.categories"}}),_c('EKInputSelect',{attrs:{"label":"دكاترة المحاضرة","placeholder":"اختر الدكاترة","options":_vm.doctors,"name":"doctors"},model:{value:(_vm.lectureDto.doctors),callback:function ($$v) {_vm.$set(_vm.lectureDto, "doctors", $$v)},expression:"lectureDto.doctors"}}),_c('label',[_vm._v("ملف المحاضرة")]),_c('b-form-file',{ref:"fileLecture",attrs:{"placeholder":"Choose a file or drop it here..."},on:{"input":function($event){return _vm.getFile($event)}}})]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }